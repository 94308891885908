import React, { useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';

export default function FooterForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: 'Message Sent Using Website Footer',
        message: ''
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = `${process.env.REACT_APP_DERRICO_API}/crm/`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            console.log(result); // Handle your success state here
            alert('Message sent successfully!');
        } catch (error) {
            console.error('There was an issue with sending the form:', error);
            alert('Failed to send message.');
        }
    };

    return (
        <div className="footer-widget footer-get-in-touch">
            <h2>Entre em Contato</h2>
            <form className="get-in-touch" onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Nome" value={formData.name} onChange={handleChange} />
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                <textarea name="message" placeholder="Escreva Sua Mensagem" value={formData.message} onChange={handleChange}></textarea>
                <button className="theme-button" type="submit"> Nos Envie Sua Mensagem <FiChevronRight className="icon" /></button>
            </form>
        </div>
    );
}
