/* eslint-disable */

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiChevronRight, FiPlay } from 'react-icons/fi'
import DERRICOExpertiseSectors from '../../assets/images/DERRICOExpertiseSectors.webp'
import DERRICOLibrary from '../../assets/images/DERRICOLibrary.webp'
import history_img from '../../assets/images/pexels-janko-ferlic-590493.jpg'
import ModalVideo from 'react-modal-video'

export default class About1 extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal2 = this.openModal2.bind(this)
    }

    openModal2() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <section className="about-st1-wrapper">
                <div className="container">
                    <div className="about-st1-grid">
                        <div className="about-left">
                            <h1 className="about-left-title">
                                Expertise Comprovada em Mais de <strong>10 Setores</strong>
                            </h1>
                            <p className="about-left-desc">
                                Com uma trajetória rica e diversificada, oferecemos soluções de Tecnologia da Informação altamente especializadas para projetos em âmbito local e internacional. Nossos serviços cobrem uma ampla gama de setores, tanto governamentais quanto privados, garantindo a adaptação e eficácia necessárias para cada contexto. Nossa expertise abrange uma variedade de áreas, desde Agricultura, Editorial e Impressão até Educação, Saúde, Aviação Civil, Engenharia Civil, Ciência & Tecnologia, Justiça e E-commerce. Cada projeto é uma oportunidade para aplicar nosso conhecimento acumulado e gerar resultados significativos.
                            </p>
                        </div>
                        <div className="about-right">
                            <img className="about-right-img" src={DERRICOExpertiseSectors} alt="About Video" />
                        </div>
                    </div>
                    <div className="history-area">
                        <div className="row">
                            <div className="col-8">
                                <img className="history_img" src={DERRICOLibrary} alt="History" />
                            </div>
                            <div className="history-details-box">
                                <h1 className="history-details-title">
                                    <strong>Três Décadas </strong>
                                    de Experiência em TI ao seu Alcance
                                </h1>
                                <p className="history-details-desc">
                                    Fundada em março de 2021, a DERRICO carrega o legado de mais de 30 anos
                                    de experiência em tecnologia da informação de seu fundador, Geison Derrico. Nosso
                                    compromisso é trazer essa rica expertise para inovar e solucionar desafios no
                                    mercado atual. Descubra como nossa experiência pode impulsionar seu projeto e levar
                                    seu negócio ao próximo nível! <br/>
                                    Conecte-se conosco hoje para explorar soluções personalizadas que atendam às suas
                                    necessidades específicas.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
