import React  from 'react'
import PortfolioDetailsContent from '../components/portfolio/PortfolioDetailsContent'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import CtaForm from '../components/other/CtaForm'
import Divider from '../components/other/Divider'
import Footer from '../components/other/Footer'
import DigitalSolutionContent from "../components/digital-solution/DigitalSolutionContent";

export default function DigitalSolutionPage() {
    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Soluções Digitais" />

            {/* Portfolio Details */}
            <DigitalSolutionContent />
            {/*<Divider />*/}

            {/* CTA Form */}
            <div className="form-white-bg">
                <CtaForm />
            </div>

            {/* Footer */}
            <Footer />
        </>
    )
}
